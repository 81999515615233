<template>
  <div class="sales-parameter">
    <PageTitle
      title="發票設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'EcommerceParameterSetting' })"
    />

    <section class="section-block">
      <SectionTitle title="發票設定" @edit="invoiceSettingDialog = true" />

      <BaseElForm :model="config" label-position="left" label-width="200px">
        <BaseElFormItem label="開放設置發票">
          <div class="secret-text collapse">
            <span>{{ config.enableOrderInvoice ? '開啟' : '關閉' }}</span>
          </div>
        </BaseElFormItem>

        <BaseElFormItem label="第三方串接發票">
          <div class="secret-text collapse">
            <span>{{ config.enableOrderInvoiceApi ? '啟用' : '不啟用' }}</span>
          </div>
        </BaseElFormItem>

        <BaseElFormItem label="使用三聯發票">
          <div class="secret-text collapse">
            <span>{{ config.enableOrderInvoiceB2B ? '開啟' : '關閉' }}</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <InvoiceDialog
      v-if="invoiceSettingDialog"
      :invoice="config"
      @close="invoiceSettingDialog = false"
      @updated="getConfig"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import SectionTitle from '@/components/Title/SectionTitle'
import InvoiceDialog from '@/views/Ecommerce/Dialog/InvoiceDialog.vue'
// import * as cssvars from '@/styles/ohbot/_variables.scss'
import { isDigitRules, noEmptyRules, urlRules, domainRules, emailRules } from '@/validation'
import formUtils from '@/utils/form'
import { GetConfig, UpdateEcommerceConfig } from '@/api/ecommerce/paramter'
import configuration from '@/configuration'
import { keys, map, filter, forEach } from 'lodash'

export default {
  name: 'EcommerceParameter',
  components: { PageTitle, SectionTitle, InvoiceDialog },
  data: () => ({
    config: {},
    tempImage: null,

    uploadDialog: false,
    pageSettingDialog: false,
    otherSettingDialog: false,
    linkSettingDialog: false,
    invoiceSettingDialog: false,

    avatarChanged: false,
    imgSize: 90,
    logoChange: false,

    formData: {
      logo: null,
      domain: '',
      customerLink: '',
      paymentDeadlineHour: '',
      floatButton: false,
      notifyEmail: '',
      social: {
        facebook: '',
        instagram: '',
        youtube: '',
        copyRight: '',
        line: '',
      },
    },
    formRules: {
      domain: [
        noEmptyRules(),
        domainRules(),
        { min: 6, message: '最少6個字元' },
      ],
      ecommerceLink: [noEmptyRules()],
      paymentDeadlineHour: [noEmptyRules(), isDigitRules()],
      customerLink: [urlRules()],
      notifyEmail: [emailRules()],
      social: {
        facebook: [urlRules()],
        instagram: [urlRules()],
        youtube: [urlRules()],
        copyRight: '',
      },
    },
  }),
  computed: {
    shopId () {
      return this.$store.getters.shop
    },
    ecommerceLink () {
      const domain = this.config.domain
      if (!domain) return '尚未設定'

      return `https://${domain}.${configuration('ecommerceHost')}`
    },
    socialLinkList () {
      const list = {}
      forEach(this.config.socialLinks, (item) => {
        if (item.type === 'line') list[item.type] = item.lineId
        else if (item.type === 'copyRight') list[item.type] = item.text
        else list[item.type] = item.link
      })
      return list
    },
  },

  async mounted () {
    await this.getConfig()
  },
  methods: {
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.getConfig()
    },
    async getConfig () {
      const [res, error] = await GetConfig({
        shopId: this.shopId,
      })
      if (error) {
        this.$message.warning(error)
        this.config = {
          Logo: null,
          domain: '',
          enableOrderInvoice: false,
          enableOrderInvoiceB2B: false,
          enableOrderInvoiceLoveCode: false,
          enableOrderInvoiceCarrier: false,
          enableOrderInvoicePrint: false,
          enableOrderWallet: false,
          paymentDeadlineHour: false,
        }
        this.formData.logo = null
        this.formData.domain = ''
        this.formData.paymentDeadlineHour = ''

        await UpdateEcommerceConfig({
          shopId: this.shopId,
          enableOrderInvoice: false,
          enableOrderInvoiceB2B: false,
          enableOrderInvoiceLoveCode: false,
          enableOrderInvoiceCarrier: false,
          enableOrderInvoicePrint: false,
          enableOrderWallet: true,
          paymentDeadlineHour: 48,
          socialLinks: [],
        })
        return
      }
      this.config = res
      this.formData.logo = res.Logo
      this.formData.domain = res.domain
      for (const k in this.formData.social) {
        this.formData.social[k] = ''
      }
      forEach(res.socialLinks, (item) => {
        if (item.type === 'line') {
          console.log('line', item, item.lineId)
          this.formData.social[item.type] = JSON.parse(JSON.stringify(item.lineId))
          console.log('line', this.formData)
        } else if (item.type === 'copyRight') this.formData.social.copyRight = item.text
        else this.formData.social[item.type] = item.link
      })
      this.formData.customerLink = res.customerServiceLink
      this.formData.paymentDeadlineHour = res.paymentDeadlineHour
      this.formData.floatButton = res.floatingButton?.enable
      this.formData.notifyEmail = res.adminNotifyEmail
    },

    // => 更新子網域
    async updateDomain () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        domain: this.formData.domain,
        customerServiceLink: this.formData.customerLink,
      })

      if (error) this.$message.error(error)
      console.log(res)
      this.linkSettingDialog = false
      await this.getConfig()
      if (!error) return this.$message.success('更新成功！')
    },

    // => 更新Logo
    async updateLogo () {
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        logo: this.formData.logo.id,
      })

      if (error) this.$message.error(error)
      console.log(res)
      this.pageSettingDialog = false
      await this.getConfig()
      if (!error) return this.$message.success('更新成功！')
    },

    // => 更新發票
    async updateInvoice () {
      const config = this.config
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        enableOrderInvoice: config.enableOrderInvoice,
        enableOrderInvoiceB2B: config.enableOrderInvoiceB2B,
        enableOrderInvoiceLoveCode: config.enableOrderInvoiceLoveCode,
        enableOrderInvoiceCarrier: config.enableOrderInvoiceCarrier,
        enableOrderInvoicePrint: config.enableOrderInvoicePrint,
      })

      if (error) this.$message.error(error)
      console.log(res)
      await this.getConfig()
      if (!error) return this.$message.success('更新成功！')
    },

    // => 更新其他設定
    async updateOtherSetting (checkInput = false) {
      if (checkInput && !(await formUtils.checkForm(this.$refs.form))) return
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        enableOrderWallet: this.config.enableOrderWallet,
        paymentDeadlineHour: !this.formData.paymentDeadlineHour
          ? undefined
          : this.formData.paymentDeadlineHour,
        adminNotifyEmail: this.formData.notifyEmail,
      })

      if (error) this.$message.error(error)
      console.log(res)

      this.otherSettingDialog = false
      if (!error) return this.$message.success('更新成功！')
    },

    // => 更新社群連結
    async updateSocial () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const socials = keys(this.formData.social)
      const linkList = map(socials, (key) => {
        if (key === 'line') {
          return {
            type: key,
            lineId: this.formData.social[key],
          }
        }
        if (key === 'copyRight') {
          return {
            type: key,
            text: this.formData.social[key],
          }
        }
        return {
          type: key,
          link: this.formData.social[key] || null,
        }
      })
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        socialLinks: filter(linkList, (item) => item.link || item.text || item.lineId),
        floatingButton: {
          type: 'socialLinkLine',
          enable: this.formData.floatButton,
        },
      })
      if (error) this.$message.error(error)
      console.log(res)
      this.pageSettingDialog = false
      await this.getConfig()
      if (!error) return this.$message.success('更新成功！')
    },
  },
}
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
section {
  margin-top: 2rem;
  margin-bottom: 50px;
}

.title {
  font-size: 20px;
  margin-right: 10px;
}

.link-card {
  margin-bottom: 20px;
}

.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
